<template>
  <div class="new-con"> 
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{$t('Contact Us')}}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section class="container text-center" style="padding-top: 36px;">
      <div class="row">
          <div class="col-md-4 col-xs-12 new-con new-con1">
            <h2>EGYPTA <span>MASSAGE</span></h2>
            <p>{{$t('wep')}}</p>
          </div>
          <div class="col-md-4 col-xs-12 new-con new-con1">

            <h4>{{$t('Address')}}</h4>
            <p>109 Rte de Luxembourg, 7241 Bereldange Walferdange</p>
          </div>
          <div class="col-md-4 col-xs-12 new-con new-con3">

            <h4>{{$t('CONTACT INFO')}}:</h4>
            <p>
              <a href="tel://+352671107278" class="contact-icon"
                >{{$t('Phone')}}: +352671107278</a
              >
              <!-- <br />
              <a href="tel://+352671107278" class="contact-icon"
                >{{$t('Mobile')}}: +352671107278</a
              > -->
              <br />
              <a href="mailto:egypta.massage@gmail.com" class="contact-icon"
                >{{$t('Email')}}: egypta.massage@gmail.com</a
              >
            </p>
          </div>
          
      </div>
    </section>
    <conactus style="background-color: white;padding-top: 20px;" />
  </div>
</template>

<script>
import conactus from './home/conactus';

export default {
  components: {
    conactus,
  },
};
</script>
<style>
.new-con h2 {
    color: #f26838;
    text-transform: uppercase;
    margin-top: 8px;
    font-size: 24px;
    font-weight: 700;
}
.new-con h2 span {
    color: #13293D;
    text-transform: uppercase;
    margin-top: 8px;
    font-size: 24px;
    font-weight: 700;
}
.new-con p a {
    color: #8B4513;
}
.new-con h4 {
    text-transform: uppercase;
    padding-bottom: 12px;
    color: #13293D;
    font-weight: 700;
}
</style>